import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Injectable } from "@angular/core";

import { HttpService } from "../shared/service/http.service";
import { DataService } from "../shared/service/data.service";
// import { AuthService } from '../shared/service/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router, 
    private httpService: HttpService,
    private dataService: DataService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    // console.log('auth guard')
    // const expectedRole = route.data['role'];
    return new Promise(async(resolve)=>{
      console.log('authGuard')
      const token = localStorage.getItem('jwtToken')
      if(token){
        try{
          const user:any = await this.httpService.autologin();
          console.log(user)
          this.dataService.user = user;
          resolve(true)
        }catch(e:any){
          this.router.navigateByUrl('/auth/login');
          resolve(false)
        }
      }else{
        this.router.navigateByUrl('/auth/login');
        resolve(false)
      }
    })
  }
}

// import { AuthService } from '../shared/service/auth.service';
// import { Injectable } from '@angular/core';
// import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
// import { Observable } from 'rxjs';
// import { take, map } from 'rxjs/operators';

// @Injectable({
//   providedIn: 'root'
// })
// export class AuthGuard implements CanActivate {
//   constructor(private router: Router, private auth: AuthService) { }

//   canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
//     const expectedRole = route.data.role;
//     return this.auth.user.pipe(
//       take(1),
//       map(user => {
//         if (!user) {
//           this.router.navigateByUrl('/auth/login');
//           return false;
//         } else {
//           let role = user['role'];
//           if (role === expectedRole) {
//             return true;
//           } else if( role === "ADMIN") {
//             this.router.navigateByUrl('/home-admin');
//           } else if( role === "PVO") {
//             this.router.navigateByUrl('/home');
//           }else{
//             alert('Foydalanuvchi rolini o\'zgartiring!');
//             return false;
//           }
//         }
//       })
//     )
//   }
// }
